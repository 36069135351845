import { Ads } from '@scm/ads'
import { EventType, GTM } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'
import { useState } from 'react'

import { ContentCardsProps } from './type'
import { ContentList } from './contentList'

const contentCards = Bem('ContentCards')

const MODULE_UID = 'module_content_cards'

// eslint-disable-next-line complexity
export const ContentCards = ({
  className,
  title,
  moduleLabel,
  link,
  contents,
  variant,
  moduleLocation,
  dateDisplay = 'show',
  contributorDisplay = 'show',
  showAll,
  displayAd,
}: ContentCardsProps) => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()
  const moduleTitle = moduleLabel || title

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: moduleTitle,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }
  const [isAdRendered, setIsAdRendered] = useState<'show' | 'hide'>('hide')
  if (displayAd && 'pos' in displayAd && displayAd.pos) {
    const checkAdRender = Ads.hooks.useAdsRender()
    checkAdRender([displayAd.pos], () => {
      setIsAdRendered('show')
    })
  }

  return (
    <div
      data-module="content-cards"
      className={contentCards({ sidebar: moduleLocation === 'sidebar' }, [
        className,
      ])}
    >
      {(variant === 'vertical' || variant === 'horizontal') &&
        displayAd.position === 'left' &&
        displayAd.pos && (
          <Ads.Ad
            pos={displayAd.pos}
            className={contentCards('Ad', { left: true, isAdRendered })}
            sizes={[
              [300, 100],
              [300, 250],
            ]}
            responsiveSizeMapping={[
              {
                width: 0,
                height: 0,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
              {
                width: 480,
                height: 200,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
            ]}
          />
        )}
      <div className={contentCards('CardsWrapper', { [variant]: true })}>
        <div
          className={contentCards('Header', { [variant]: true })}
          data-testid="content-cards-header"
        >
          <h3
            className={contentCards('Title')}
            data-testid="contentCardsTitle"
            dangerouslySetInnerHTML={{
              __html: renderMarkdown(moduleLabel || title),
            }}
          />
          {link && link.href && link.label && (
            <Link
              className={contentCards('Link')}
              data-testid="content-card-link"
              to={link.href}
              trackGtmClick={trackGtmClick}
            >
              <span
                className={contentCards('LinkText')}
                data-testid="contentCardsBreadcrumbsLinkTitle"
              >
                {link.label}
              </span>
              <Icon icon="ChevronRight" className={contentCards('LinkIcon')} />
            </Link>
          )}
        </div>
        <ContentList
          variant={variant}
          showAll={showAll}
          contents={contents}
          trackGtmClick={trackGtmClick}
          trackGtmUid={MODULE_UID}
          position={displayAd?.position}
          isShowDate={dateDisplay === 'show'}
          isShowContributor={contributorDisplay === 'show'}
          trackGtmTitle={moduleTitle}
        />
      </div>
      {(variant === 'vertical' || variant === 'horizontal') &&
        displayAd.position === 'right' &&
        displayAd.pos && (
          <Ads.Ad
            pos={displayAd.pos}
            className={contentCards('Ad', { right: true, isAdRendered })}
            sizes={[
              [300, 100],
              [300, 250],
            ]}
            responsiveSizeMapping={[
              {
                width: 0,
                height: 0,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
              {
                width: 480,
                height: 200,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
            ]}
          />
        )}
    </div>
  )
}
